<template>
  <div class="f-modal-alert">
    <div class="f-modal-icon f-modal-warning scaleWarning">
      <span class="f-modal-body pulseWarningIns"></span>
      <span class="f-modal-dot pulseWarningIns"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "warning-icon",
  data() {
    return {};
  }
};
</script>
<style lang="scss">


.f-modal-alert .f-modal-icon {

	
	// Warning icon
	
	&.f-modal-warning {
		border-color: #F8BB86;
		
		&:before {
			animation: pulseWarning 2s linear infinite;
			background-color: #fff;
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 100%;
			opacity: 0;
			position: absolute;
			width: 100%;
		}
		
		&:after {
			background-color: #fff;
			border-radius: 50%;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 1;
		}
	}

	&.f-modal-warning .f-modal-body {
		background-color: #F8BB86;
		border-radius: 2px;
		height: 47px;
		left: 50%;
		margin-left: -2px;
		position: absolute;
		top: 10px;
		width: 5px;
		z-index: 2;
	}

	&.f-modal-warning .f-modal-dot {
		background-color: #F8BB86;
		border-radius: 50%;
		bottom: 10px;
		height: 7px;
		left: 50%;
		margin-left: -3px;
		position: absolute;
		width: 7px;
		z-index: 2;
	}
	
	+ .f-modal-icon {
		margin-top: 50px;
	}
}

.animateSuccessTip {
	animation: animateSuccessTip .75s;
}

.animateSuccessLong {
	animation: animateSuccessLong .75s;
}

.f-modal-icon.f-modal-success.animate:after {
	animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
	animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
	animation: animateErrorIcon .5s;
}

.animateXLeft {
	animation: animateXLeft .75s;
}

.animateXRight {
	animation: animateXRight .75s;
}

.scaleWarning {
    animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
    animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {
	0%,54% {
		width: 0;
		left: 1px;
		top: 19px;
	}

	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}

	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}

	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes animateSuccessLong {
	0%,65% {
		width: 0;
		right: 46px;
		top: 54px;
	}

	84% {
		width: 55px;
		right: 0;
		top: 35px;
	}

	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@keyframes rotatePlaceholder {
	0%,5% {
		transform: rotate(-45deg);
	}

	100%,12% {
		transform: rotate(-405deg);
	}
}

@keyframes animateErrorIcon {
	0% {
		transform: rotateX(100deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0deg);
		opacity: 1;
	}
}

@keyframes animateXLeft {
	0%,
	65% {
		left: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		left: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		left: 17px;
		top: 37px;
		width: 47px;
	}
}

@keyframes animateXRight {
	0%,
	65% {
		right: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		right: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		right: 16px;
		top: 37px;
		width: 47px;
	}
}

@keyframes scaleWarning {
	0% {
		transform: scale(1);
	}
	
	30% {
		transform: scale(1.02);
	}
	
	100% {
		transform: scale(1);
	}
}

@keyframes pulseWarning {
	0% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	30% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		background-color: #F8BB86;
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}


</style>