import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export const routes = [
    {
        path: "/resultados-da-pesquisa",
        name: "results-found",
        meta: {
            breadcrumb: [
                { text: "Home", to: "/", active: false },
                { text: "Resultados", active: true },
            ],
        },
        component: () =>
            import("@/views/services/results-found/results-found.vue"),
    },
    {
        path: "/dasa/rede-de-atendimento",
        name: "results-found-dasa",
        meta: {
            breadcrumb: [
                { text: "Home", to: "/", active: false },
                { text: "Resultados", active: false },
            ],
            header: {
                clean: true,
                hide: false,
                logo: "dasa-filoo.png"
            },
            footer: {
                hide: true,
            },
        },
        component: () =>
            import(
                "@/views/services/results-found-dasa/results-found-dasa.vue"
            ),
    },
    {
        path: "/profissional-da-saude/:id/:webClinicId?",
        name: "doctor",
        meta: {
            breadcrumb: [
                { text: "Home", active: false },
                { text: "Resultados", active: false },
                { text: "Profissional", active: true },
            ],

            sitemap: {
                slugs: [{ id: 1, webClinicId: 1 }],
            },
        },
        component: () => import("@/views/services/doctor/doctor.vue"),
    },
    {
        path: "/planos",
        name: "plans",
        meta: {
            breadcrumb: [
                { text: "Home", active: false },
                { text: "Planos", active: true },
            ],
        },
        component: () => import("@/views/services/plans/plans.vue"),
    },
    {
        path: "/exibir-localizacao",
        name: "show-location",
        component: () =>
            import("@/views/services/show-location/show-location.vue"),
    },
    {
        path: "/exame-laboratorio",
        name: "exams",
        props: true,
        component: () =>
            import("@/views/services/exams-results/exam-results.vue"),
    },
    {
        path: "/remedio",
        name: "drugs",
        props: true,
        component: () =>
            import("@/views/services/medicine-results/medicine-results.vue"),
    },
    {
        path: "/hora-da-saude",
        name: "sign-up-horas-da-vida",
        component: () =>
            import(
                "@/views/sign-up-horas-da-vida/sign-up-horas-da-vida.vue"
            ),
    },
    {
        path: "/hora-da-saude/confirmacao-de-cadastro",
        name: "hora-da-saude-registration-confirmation",
        component: () =>
            import(
                "@/views/sign-up-horas-da-vida/registration-confirmation/registration-confirmation.vue"
            ),
    },
    {
        path: "/cadastro-clinica",
        name: "sign-in-clinic",
        component: () =>
            import("@/views/services/register-user-clinic/register"),
    },
    {
        path: "/cadastro-medico",
        name: "sign-in-doctor",
        component: () =>
            import("@/views/services/health-professional-register/register"),
    },
    {
        path: "/cupom/:code",
        name: "apply-coupon",
        meta: {
            breadcrumb: [
                { text: "Home", active: false },
                { text: "Planos", active: false },
                { text: "Cupons", active: true },
            ],
            sitemap: {
                slugs: ["plan-coupons"],
            },
        },
        component: () => import("@/views/services/coupons/coupons.vue"),
    },
    {
        path: "/faca-parte-:code",
        name: "apply-coupon-old",
        props: {
            showCupomInput: false,
        },
        meta: {
            sitemap: {
                slugs: ["partner-coupons"],
            },
        },
        beforeEnter: (to, from, next) => {
            sessionStorage.setItem("thirdParty", to.params.code);
            localStorage.setItem("multPlanCode", to.params.code);
            next();
        },
        component: () => import("@/views/services/coupons/coupons.vue"),
    },
];

