import Vue from 'vue'
import VueSessionStorage from 'vue-sessionstorage'
import VueGtm from 'vue-gtm-custom'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'
import VueSession from 'vue-session'
import VueTheMask from 'vue-the-mask'
import VueMeta from 'vue-meta'
import money from 'v-money'
import VueSwal from 'vue-swal'
import moment from 'moment'
import BootstrapVue from 'bootstrap-vue'
import Vuesax from 'vuesax'
import VueSlider from 'vue-slider-component'
import VueFormWizard from 'vue-form-wizard'
import Tooltip from 'vue-directive-tooltip'
import Multiselect from 'vue-multiselect'
import JsonCSV from 'vue-json-csv'
import JsonExcel from 'vue-json-excel'
import ViaCep from 'vue-viacep'
import LoadScript from 'vue-plugin-load-script'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as Sentry from '@sentry/vue'

import { BootstrapVueIcons } from 'bootstrap-vue'
import { VueHammer } from 'vue2-hammer'
import { Map, TileLayer, OsmSource, Geoloc } from 'vuelayers'

import App from './App.vue'
import router from './router/router'
import store from './store/store'
import scripts from './setup/scripts-list'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'material-icons/iconfont/material-icons.css' // Material Icons
import 'vuesax/dist/vuesax.css'
import 'vue-slider-component/theme/default.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import './filters/filters'
import '../themeConfig.js'
import './support/globalComponents.js'
import './assets/scss/main.scss'
import './assets/css/vuelayers.css' // needs css-loader
import '@/assets/css/main.css'
  
require('./assets/css/iconfont.css')
require('./assets/css/themify-icons.css')

Vue.prototype.moment = moment
Vue.prototype.loader = (show = false) => {
  const loader = document.getElementById('loading-bg')
  
  if (show) {
    loader.classList.add('navigator')
    loader.style.display = 'block'

    setTimeout(() => {
      loader.classList.add('show')
    });
  } else {
    loader.classList.remove('show')

    setTimeout(() => {
      loader.style.display = 'none'
      loader.classList.remove('navigator')
    }, 250)
  }
}

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})

Vue.use(VueSessionStorage)
Vue.use(VueAxios, axios)
Vue.use(VueApexCharts)
Vue.use(VueCookies)
Vue.use(VueSession, { persist: true })
Vue.use(VueTheMask)
Vue.use(VueMeta)
Vue.use(money, { precision: 4 })
Vue.use(VueSwal)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuesax)
Vue.use(VueFormWizard)
Vue.use(Tooltip)
Vue.use(Multiselect)
Vue.use(VueHammer)
Vue.use(Map)
Vue.use(TileLayer)
Vue.use(OsmSource)
Vue.use(Geoloc)
Vue.use(ViaCep)
Vue.use(LoadScript)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAdwxi7P7SUDsbcxa3RRcQ315FDIOh1log',
    libraries: 'places'
  }
})

Vue.component('apexchart', VueApexCharts)
Vue.component('VueSlider', VueSlider)
Vue.component('multiselect', Multiselect)
Vue.component('downloadCsv', JsonCSV)
Vue.component('downloadExcel', JsonExcel)

scripts.forEach(scriptItem => {
  const isDev = window.location.host.indexOf('checkout.filoo') < 0

  Vue.loadScript(scriptItem.url)
    .then((data) => isDev ? console.log(`${scriptItem.name} Loaded`) : '')
    .catch(() => console.erro(`Error loading ${scriptItem.name}`))
})

if (process.env.VUE_APP_SENTRY_DSN_URL) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN_URL,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

Vue.config.productionTip = false

VueCookies.config('2d')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
