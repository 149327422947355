import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/main-page/MainPage.vue'),
  children: [
    {
      path: '/insurance',
      name: 'insurance',
      component: () =>
        import('@/views/insurance/insurance.vue')
    },
  ]
}
export default [
  routes
]

