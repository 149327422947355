import Vue from 'vue'
import Vuex from 'vuex'

import { state } from "./state"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

Vue.use(Vuex)

const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
})

export default store
export const useStore = () => store
