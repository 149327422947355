import Axios from 'axios'

export const actions = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width);
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_IS_MOBILE', width < 600)
    commit('UPDATE_IS_TABLET', width >= 600 && width < 960)
  },


  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },
  saveLaboratory({ commit }, laboratory) {
    commit('SAVE_LABORATORY', laboratory)
  },
  saveDrugstore({ commit }, drugstore) {
    commit('SAVE_DRUGSTORE', drugstore)
  },

  setEnviroment({ commit }, url) {
    const isLocal = url.indexOf("localhost") > 0
    const isDev = url.indexOf("//dev")
    const isHomol = url.indexOf("//homolog")

    commit("UPDATE_ENVIROMENT", isLocal || isDev || isHomol)
  },

  // APIS
  async getBlogPosts({ state, commit }) {
    const { status, data: posts } = await Axios.get(
      `${state.filooUrlBlog}posts?_embed&page=1`
    )

    if (status === 200) {
      posts.forEach((post) => {
        post.image = post._embedded['wp:featuredmedia'][0].source_url
        post.categories = post.categories.map((cat) => post._embedded['wp:term'][0].find((term) => term.id === cat))
      })
      
      commit('SET_BLOG_POSTS', posts)
    }
  },

  async getAddressByZipcode({ state }, cep) {
    const { status, data, error } = await Axios.get(`${state.filooServer}cep/${cep}`)
      .catch((error) => error.response.data)

    if (error) {
      console.error(error)
    }

    return status === 200 ? data : null
  },

  async getGoogleAddress({ state }, { cep, lat, lng }) {
    const url = [`key=${state.googleMapKey}`]

    if (cep) {
      url.push(`address=${cep}`)
    } 
    if (lat && lng) {
      url.push(`latlng=${lat},${lng}`)
    }

    const { status, data } = await Axios.get(
      `${state.googleMapUrl}api/geocode/json?${url.join('&')}`
    )

    return status === 200 && data.status === 'OK' ? data.results[0] : null
  },

  async getExamsByTerm({ state, commit }, term, page) {
    const { status, data } = await Axios.get(
      `${state.filooServer}website/exam/searchExamByWord?page=${page}&search=${term}&size=10&sort=name,asc`
    )

    commit('SET_EXAMS', status === 200 ? data.content : [])
  },

  async getLocationsByTerm({ state, commit }, term, page = 0) {
    const { status, data } = await Axios.get(
      `${state.filooServer}website/searchLocations?page=${page}&search=${term}&size=10&sort=city,asc`
    )

    commit('SET_LOCATIONS', status === 200 ? data.content : [])
  },

  async getDoctorsList({ state }, { lat, lng, specialty, page = 0 }) {
    return await Axios.post(
      `${state.filooServer}website/searchDoctors?page=${page}&size=9&sort=name,asc`,
      {
        address: {
          latitude: lat,
          longitude: lng
        },
        specialty
      }
    )
  },

  async getExamsList({ state }, params, page = 0) {
    return await Axios.post(
      `${state.filooServer}website/exam/listExams?page=${page}&size=10&sort=name,asc`,
      params
    )
  },

  async getPlanByCode({ state, commit }, code) {
    const endPoint = `customerplan/find_by_code/${code}`

    const response = await Axios.get(`${state.filooServer}${endPoint}`)
      .catch((error) => console.error({
        error,
        endpoint: endPoint,
        method: "find_by_code"
      }))

    commit('SET_PLAN', response && response.data ? response.data : null)

    return response && response.data ? response.data : null
  },

  async getPlans({ state, commit }, plan) {
    const { status, data } = await Axios.get(
      `${state.filooServer}customerplan/multplancode/${plan}`
    )

    commit('SET_PLANS', status === 200 ? data : [])

    return status === 200 ? data : []
  },

  async validateCpf({ state }, cpf) {
    const { data } = await Axios.get(
      `${state.filooServer}customerplan/validateCpf/${cpf}`
    )

    return data || false
  },

  async validateEmail({ state }, email) {
    const { data } = await Axios.post(
      `${state.filooServer}customerplan/validateEmail`,
      { email }
    )

    return data || false
  },

  async getSpecialties({ state, commit }) {
    const { status, data: specialties } = await Axios.get(
      `${state.filooServer}specialties/doctors`
    )

    commit('SET_SPECIALTIES', status === 200 ? specialties : [])
  },

  async setNewLeads({ state }, data) {
    return await Axios.post(
      `${state.filooServer}integra/register/leads`,
      data
    )
      .catch((error) => error.response.data)
  },

  async setNewRegister({ state }, data) {
    return await Axios.post(
      `${state.filooServer}integra/register`,
      data
    )
      .catch((error) => error.response.data)
  },

  // ORIZON
  async getOrizonLabs({ state }, { lat, lng, page = 0 }) {
    return await Axios.post(
      `${state.filooServer}orizon/labsdist?page=${page}&size=10&sort=name,asc`,
      { lat, lng }
    )
  },

  // TOPMED
  async getTopmedPharma({ state }, data) {
    return await Axios.post(
      `${state.filooServer}topmed/farma`,
      data
    )
  },

  // DASA
  async getLabsList({ state }, params, page = 0) {
    return await Axios.post(
      `${state.filooServer}dasa/listLabs?page=${page}&size=10&sort=name,asc`,
      params
    )
  },

  // DASA
  async getDrugstoresList({ state }, params, page = 0) {
    return await Axios.post(
      `${state.filooServer}dasa/listDrugstores?page=${page}&size=10&sort=commercialName,asc`,
      params
    )
  },

  // DASA
  async getLabsByTerm({ state, commit }, term, page = 0) {
    const { status, data } = await Axios.get(
      `${state.filooServer}dasa/searchLabByWord?page=${page}&search=${term}&size=10&sort=name,asc`
    )

    commit('SET_LABORATORIES', status === 200 ? data.content : [])
  },

  // DASA
  async getDrugstoreByTerm({ state, commit }, term, page = 0) {
    const { status, data } = await Axios.get(
      `${state.filooServer}dasa/searchDrugstoreByWord?page=${page}&search=${term}&size=10sort=commercialName,asc`
    )

    commit('SET_DRUGSTORES', status === 200 ? data.content : [])
  },
}