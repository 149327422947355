import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/full-page/FullPage.vue'),
  children: [
    {
      path: '/parcerias/:partner',
      name: 'landing-pages',
      meta: {
          sitemap: {
              slugs: ['parceiro']
          }
      },
      beforeEnter: (to, from, next) => {
        sessionStorage.setItem("partner",  to.params.partner);
        sessionStorage.setItem("thirdParty",  to.params.partner);
        if(to.params.partner.toLowerCase() === 'bbzadm'.toLowerCase() || to.params.partner.toLowerCase() === 'bbz'.toLowerCase()) {
          sessionStorage.setItem("partner",  'bbz');
          sessionStorage.setItem("thirdParty",  'bbz');
          next("/parceria/bbz");
        }
        next();
      },
      component: () =>
        import('@/views/landing-pages/landing-page.vue')
    },
  ]
}
export default [
  routes
]

