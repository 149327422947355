import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/main-page/MainPage.vue'),
  children: [
    {
      path: '/carreiras',
      name: 'careers',
      component: () =>
        import('@/views/careers/careers.vue')
    },
    {
      path: '/termo-de-consentimento-candidato',
      name: 'user-terms-careers',
      component: () =>
        import('@/views/user-terms-careers/user-terms-careers.vue')
    },
    
  ]
}
export default [
  routes
]

