<template>
	<div id="app">
		<router-view  :key="$route.fullPath" ></router-view>
	</div>
</template>

<script>
import { useStore } from "@/store/store.js"
import themeConfig from '@/../themeConfig.js'

export default {
  metaInfo: {
    title: 'Filoo - As melhores ofertas de saúde',
    htmlAttrs: {
      lang: 'pt-BR'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'Filóo, Saúde, Exame, Medicamento'},
      { name: 'description', content: 'Aqui você encontra as melhores ofertas de pronto atendimento virtual, descontos em exames e medicamentos. Contrate a opção de serviço que melhor atenda a sua necessidade.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1'},
      { name: 'google-site-verification', content: 'MAfWC-rjIDgR0rGKK_J9ob1eF_xrpUi6ToNiIZQc3uQ'},
      { name: 'facebook-domain-verification', content: 'yffsjqa5dzuro5bvrig98kkbcv8yj5'},
      { name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'},
      { name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'},
    ]
  },

  setup () {
    const store = useStore()

    return {
      setWindowWidth: (size) => store.dispatch('updateWindowWidth', size),
      setEnviroment: (url) => store.dispatch("setEnviroment", url),
    }
  },

  async created() {
    await this.setEnviroment(window.location.origin)
  },

  async mounted() {
    this.toggleClassInBody(themeConfig.theme)
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize)
    })

    await this.handleWindowResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    },
    handleWindowResize() {
      this.setWindowWidth(document.documentElement.clientWidth)
    },
  },

  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    }
  },
}
</script>
