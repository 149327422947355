import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/main-page/MainPage.vue'),
  children: [
    {
      path: '/blog',
      name: 'blog',
      component: () =>
        import('@/views/blog/blog.vue')
    },
    {
      path: '/:year/:month/:day/:slug',
      name: 'blog-post',
      props: true,
      meta: {
        sitemap: {
          slugs: [
            {
              year: 2022,
              month: 1,
              day: 1,
              slug: 'article-slug'
            }
          ]
        }
      },
      component: () =>
        import('@/views/blog/blog-post/blog-post.vue')
    },
    {
      path: '/:year/:month/:day/:id/:slug',
      name: 'blog-post-id',
      props: true,
      meta: {
        sitemap: {
          slugs: [
            {
              id: 1,
              year: 2022,
              month: 1,
              day: 1,
              slug: 'article-slug'
            }
          ]
        }
      },
      component: () =>
        import('@/views/blog/blog-post/blog-post.vue')
    },
    {
      path: '/tag/:slug',
      name: 'blog-tag',
      props: true,
      meta: {
        sitemap: {
          slugs: ['searched-tags']
        }
      },
      component: () =>
        import('@/views/blog/blog-tag/blog-tag.vue')
    },
  ]
}
export default [
  routes
]

