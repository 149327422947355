import { routes as blog } from "../views/blog"
import { routes as destinedFor } from "../views/destined-for"
import { routes as contactUs } from "../views/contact-us"
import { routes as insurance } from "../views/insurance"
import { routes as socialResponsability } from "../views/social-responsability"
import { routes as careers } from "../views/careers"
import { routes as recommendAHealthcareProfessional } from "../views/recommend-a-healthcare-professional"
import { routes as landingPage } from "../views/landing-page"
import { routes as landingPages } from "../views/landing-pages"

import { routes as servicesRoutes } from './services/routes.js'

export default [
  {
    path: "",
    component: () =>
      import("@/layouts/main-page/MainPage.vue"),
    children: [
      ...servicesRoutes,
      {
        path: '/',
        name: 'home',
        props: true,
        beforeEnter: (to, from, next) => {
          let partner = sessionStorage.getItem("partner");
          let thirdParty = sessionStorage.getItem("thirdParty")

          if(!!partner && partner !== 'null' && partner === thirdParty) {
            next({path: '/parcerias/' + partner})
          } else if(partner === 'bbz' && thirdParty === 'bbzadm') {
            next({path:"/parceria/bbz"});
          } else {
            next()
          }
        },
        component: () =>
          import('@/views/Home/Home.vue')
      },
      {
        path: '/sobre-nos',
        name: 'about-us',
        component: () =>
          import('@/views/about-us/about-us.vue')
      },
      {
        path: "/nossos-produtos",
        name: "our-products",
        meta: {
          breadcrumb: [
            { text: "Home", active: false },
            { text: "Nossos Produtos", active: true },
          ],
        },
        component: () =>
          import("@/views/product-list/product-list.vue")
      },
      {
        path: "/sobre/:name",
        name: "product-detailing",
        props: true,
        meta: {
          sitemap: {
            slugs: ["product-detailing"]
          },
          breadcrumb: [
            { text: "Home", active: false },
            { text: "Nossos Produtos", active: false },
            { text: "Produto", active: true },
          ],
        },
        component: () =>
          import("@/views/product-detailing/product-detailing.vue")
      },
      {
        path: '/rede-de-atendimento',
        name: 'attendance',
        component: () =>
          import('@/views/attendance/attendance.vue')
      },
      {
        path: "/seja-um-parceiro",
        name: "become-a-partner",
        component: () =>
          import("@/views/become-a-partner/become-a-partner.vue")
      },
      {
        path: "/farmacias-e-drogarias",
        name: "pharmacy",
        component: () => import("@/views/pharmacy/pharmacy.vue")
      },
      {
        path: "/laboratorios",
        name: "laboratories",
        component: () => import("@/views/laboratory/laboratory.vue")
      },
      {
        path: '/termos-de-uso-paciente',
        name: 'user-terms-client',
        component: () =>
          import('@/views/user-terms-client/user-term-client')
      },
      {
        path: '/termos-e-condicoes-de-uso-dos-profissionais-da-saude',
        name: 'user-terms-partner',
        component: () =>
          import('@/views/user-terms-partner/user-term-partner')
      },
      {
        path: '/politica-de-privacidade-candidato',
        name: 'candidate-privacy-policy',
        component: () =>
          import('@/views/candidate-privacy-policy/candidate-privacy-policy')
      },
      {
        path: '/termos-de-consentimento-candidato',
        name: 'candidate-agree-terms',
        component: () =>
          import('@/views/candidate-agree-terms/candidate-agree-terms')
      },
      {
        path: '/politica-de-privacidade',
        name: 'privacy-policy',
        component: () =>
          import('@/views/privacy-policy/privacy-policy')
      },
      {
        path: "/entrar",
        name: "SignIn",
        component: () => import("@/views/SignIn/SignIn.vue"),
      },   
      {
        path: "/confirmacao-pedido",
        name: "PaymentConfirmation",
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('restart')) {
            localStorage.removeItem('acceptTerm')
            localStorage.removeItem('customer')
            localStorage.removeItem('restart')
      
            next(`/cadastre-se?plan_code=${localStorage.getItem('planCode')}`)
          } else {
            next()
          }
        },
        component: () =>
          import("@/views/PaymentConfirmation/PaymentConfirmation.vue"),
        meta: {
          header: {
            clean: true
          },
          footer: {
            hide: true
          }
        }
      }
    ]
  },
  ...blog,
  ...destinedFor,
  ...contactUs,
  ...insurance,
  ...socialResponsability,
  ...careers,
  ...recommendAHealthcareProfessional,
  ...landingPage,
  ...landingPages,
  {
    path: "/cadastre-se",
    name: "SignUp",
    component: () => import("@/views/SignUp/SignUp.vue"),
  },
  {
    path: "/rede-credenciada",
    name: "AccreditedNetwork",
    component: () => import("@/views/AccreditedNetwork/AccreditedNetwork.vue"),
  },
  {
    path: "/cadastre-se",
    name: "SignUp",
    component: () => import("@/views/SignUp/SignUp.vue"),
  },
  {
    path: '/doctors-results-not-found',
    name: 'doctors-results-not-found',
    component: () =>
      import('@/views/errors/doctors-results-not-found.vue')
  },
  {
    path: '*',
    name: 'error404',
    component: () =>
      import('@/views/errors/error404.vue')
  },
]
