import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import qs from 'qs';

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    var result = qs.stringify(query);

    return result ? ('?' + result) : '';
  },
  routes,
  mode: 'history'

})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
