import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/main-page/MainPage.vue'),
  children: [
    {
      path: '/responsabilidade-social',
      name: 'social-responsability',
      component: () =>
        import('@/views/social-responsability/social-responsability.vue')
    },
  ]
}
export default [
  routes
]

