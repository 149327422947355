export const mutations = {
    UPDATE_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
        state.sidebarItemsMin = val;
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val;
    },
    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val;
    },
    TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    UPDATE_THEME(state, val) {
        state.theme = val;
    },
    UPDATE_WINDOW_BREAKPOINT(state, val) {
        state.breakpoint = val;
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val;
    },
    UPDATE_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile;
    },
    UPDATE_IS_TABLET(state, isTablet) {
      state.isTablet = isTablet;
    },
    UPDATE_NAVBAR(state, value) {
        state.updateNavBar = value;
    },

    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
        // update the main list
        state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

        // if val is true add it to starred else remove
        if(payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList.data[index])
        }else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.index == payload.index)
            // remove item using index
            state.starredPages.splice(index, 1);
        }
    },

    // The Navbar
    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10);
        state.starredPages = list.concat(starredPagesMore);
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10];
        const starredPagesLimited = state.starredPages.slice(0, 10);
        state.starredPages = starredPagesLimited.concat(list);

        state.starredPages.slice(0,10).map((i) => {
            if(list.indexOf(i) > -1) downToUp = true
        })
        if(!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited);
        }
    },
    SAVE_LABORATORY(state, laboratory) {
        state.laboratory = laboratory;
    },
    SAVE_DRUGSTORE(state, drugstore) {
        state.drugstore = drugstore;
    },
    
    UPDATE_ENVIROMENT(state, value) {
      state.isDev = value
    },
    
    // HOME
    SET_ADDONS(state, addons) {
        state.addons = addons
    },
    SET_BLOG_POSTS(state, blogPosts) {
        state.blogPosts = blogPosts
    },
    SET_EXAMS(state, exams) {
        state.exams = exams
    },
    SET_LABORATORIES(state, laboratories) {
        state.laboratories = laboratories
    },
    SET_DRUGSTORES(state, drugstores) {
        drugstores.forEach(d => {
            d.name = d.commercialName
        });
        state.drugstores = drugstores
    },
    SET_LOCATIONS(state, locations) {
        state.locations = locations
    },
    SET_PLAN(state, plan) {
        state.plan = plan
    },
    SET_PLANS(state, plans) {
        state.plans = plans
    },
    SET_SPECIALTIES(state, specialties) {
        state.specialties = specialties
    },
}
