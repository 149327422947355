export const getters = {
  isDev: (state) => state.isDev,
  device: (state) => {
    if (state.isMobile) {
      return 'mobile'
    }
    if (state.isTablet) {
      return 'tablet'
    }
    return 'desktop'
  },
  blogPosts: (state) => state.blogPosts,
  exams: (state) => state.exams,
  laboratories: (state) => state.laboratories,
  drugstores: (state) => state.drugstores,
  specialties: (state) => state.specialties,
  locations: (state) => state.locations,
  plans: (state) => state.plans,
}
