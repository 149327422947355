import navbarSearchAndPinList from "@/constants/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"

export const state = {
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || "light",
    navbarSearchAndPinList: navbarSearchAndPinList,
    AppActiveUser: {
        id: 0,
        name: "Mr Dev",
        about: "About info here",
        img: "avatar-s-11.png",
        status: "online",
    },
    filooWebSiteUrl: process.env.VUE_APP_BASE_URL,
    filooServer: process.env.VUE_APP_BASE_URL_BACK,
    filooUrlClient: process.env.VUE_APP_BASE_URL_CLIENT_FILOO,
    filooUrlDoctor: process.env.VUE_APP_BASE_URL_DOCTOR_FILOO,
    filooUrlClinic: process.env.VUE_APP_BASE_URL_CLINIC_FILOO,
    filooUrlBlog: process.env.VUE_APP_BASE_URL_BLOG_FILOO,
    filooMixpanelId: process.env.VUE_APP_MIXPANEL_ID,
    iuguNotifyAccountId: process.env.VUE_APP_IUGU_NOTIFY_ACCOUNT_ID,
    iuguTestMode: process.env.VUE_APP_IUGU_TEST_MODE !== "false",
    googleMapUrl: process.env.VUE_APP_GMAPS_URL,
    googleMapKey: process.env.VUE_APP_GMAPS_KEY,
    themePrimaryColor: colors.primary,

    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    userRole: null,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar component
    updateNavBar: false,
    laboratory: null,
    drugstore: null,
    
    // plans
    isDev: false,
    isMobile: false,
    isTablet: false,
    addons: [],
    blogPosts: [],
    exams: [],
    laboratories: [],
    drugstores: [],
    locations: [],
    plan: null,
    plans: [],
    specialties: []
}
