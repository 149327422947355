import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = {
  path: '',
  component: () =>
    import('@/layouts/full-page/FullPage.vue'),
  children: [
    {
      path: '/ativacao',
      name: 'filoo-activation',
      meta: {
          sitemap: {
              slugs: ['plan-activation']
          }
      },
      component: () =>
        import('@/views/landing-page/filoo-activation/filoo-activation.vue')
    },
    {
      path: '/parceria/bbz',
      name: 'landing-page-bbz',
      beforeEnter: (to, from, next) => {
        sessionStorage.setItem("partner",  "bbz");
        sessionStorage.setItem("thirdParty",  "bbz");
        next();
      },
      component: () =>
        import('@/views/landing-page/bbz/home/home.vue')
    },
    {
      path: '/parceria/cufa',
      name: 'landing-page-cufa',
      beforeEnter: (to, from, next) => {
        sessionStorage.setItem("partner",  "cufa");
        sessionStorage.setItem("thirdParty",  "cufa");
        next();
      },
      component: () =>
        import('@/views/landing-page/cufa/home/home.vue')
    },
    {
      path: '/parceria/salaryfits',
      name: 'landing-page-salaryfits',
      beforeEnter: (to, from, next) => {
        sessionStorage.setItem("partner",  "salaryfits");
        sessionStorage.setItem("thirdParty",  "salaryfits");
        next();
      },
      component: () =>
        import('@/views/landing-page/salaryfits/home/home.vue')
    },
  ]
}
export default [routes]

